import React, { Component } from 'react';

export default class Experiences extends Component {
  render() {
    return (
      <div>
        <section className='colorlib-experience' data-section='experiences'>
          <div className='colorlib-narrow-content'>
            <div className='row'>
              <div
                className='col-md-6 col-md-offset-3 col-md-pull-3 animate-box'
                data-animate-effect='fadeInLeft'
              >
                <span className='heading-meta'>Current and past endeavors</span>
                <h2 className='colorlib-heading animate-box'>Experiences</h2>
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <div className='experiences-centered'>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInTop'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-2'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineer at Google​{' '}
                          <span>July 2023-Current</span>
                        </h2>
                        <p>
                          I am enhancing the experience of external and internal
                          Google Cloud customers and users by analyzing aspects
                          such as user traffic, errors, and latency, and
                          designing, implementing, and improving load balancing
                          pipelines and algorithms for these analyses in C++. I
                          also make make improvements to the metrics used to
                          identify load balancing improvements by designing and
                          implementing crucial charts and queries.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-3'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineering Intern at Meta{' '}
                          <span>June 2022-September 2022</span>
                        </h2>
                        <p>
                          I built an infrastructure tool that dropped the number
                          of crashes/bugs on Meta products/sites and increased
                          engineer productivity by automating code syncing
                          between two of Meta’s largest mono repositories as
                          they share many libraries and code. The tool shows
                          code sync previews while code is under review to
                          ensure the syncs will not cause crashes in the other
                          repository. Once the original code is approved and
                          pushed to production, the synced code is automatically
                          pushed to production by this tool.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-4'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineering Intern at Google{' '}
                          <span>March 2022-June 2022</span>
                        </h2>
                        <p>
                          As an intern on Google Geo's Local AI team, I built a
                          machine learning pipeline in C++ that efficiently
                          filtered through billions of edits using MapReduce to
                          generate positive labeled training data to retrain and
                          potentially improve the accuracy of multiple machine
                          learning models within Google Maps. Positive labeled
                          training data is difficult to mine and previously only
                          made up less than 8% of Google Geo Local AI team’s
                          training data, my pipeline almost quadrupled the
                          number of positive labeled training data.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-5'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineering Intern at Google{' '}
                          <span>June 2021-September 2021</span>
                        </h2>
                        <p>
                          In order to improve the WiFi chip and firmware quality
                          from chip vendors, I designed and developed the
                          infrastructure of a qualification tool for vendors to
                          self certify their deliverables and serve as an
                          internal sanity check tool that runs regularly to find
                          any potential WiFi/Bluetooth connectivity issues for
                          Google Nest’s Platform Connectivity Team. I also
                          designed and implemented a graphical user interface to
                          help vendors visualize the statistics collected from
                          these tests, which included automatic emails with test
                          summaries and Google Drive upload of detailed test
                          logs.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-1'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineering R&D Intern at Sandia National
                          Labs <span>June 2020-June 2021</span>
                        </h2>
                        <p>
                          I use medical imaging software, Pydicom, and the DICOM
                          dictionary data structure to write Python software
                          that increases the efficiency of sharing, viewing, and
                          analyzing multi-layered flight and ground test data of
                          U.S. nuclear weapons. I also created a Flask web-app
                          to efficiently convert the data in large csv files to
                          xkcd graphs.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInTop'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-2'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Undergraduate Researcher at Engineers for Exploration​{' '}
                          <span>January 2020-June 2021</span>
                        </h2>
                        <p>
                          I tracked baboon movements and behaviors through drone
                          footage by applying concepts in machine learning and
                          computer vision such as neural networks, Kalman
                          filters, blob detection, mask generation, and centroid
                          calculations. My work accelerated the Uaso Ngiro
                          Baboon Project by simplifying the data to ease the
                          analysis of baboon troop dynamics. In addition, I
                          helped develop a new Agile inspired business model
                          that makes the workflow at Engineers for Exploration
                          more efficient. Our team was invited to the Measuring
                          Behavior 2020 International Conference in Poland after
                          publishing a paper on our research. Feel free to check
                          out our paper on page 33 below!
                        </p>
                        <p>
                          <a
                            className='btn btn-primary btn-learn'
                            href='https://www.researchgate.net/profile/Andrew-Spink/publication/344557101_Volume_1_of_the_Proceedings_of_the_joint_meeting_of_the_12th_International_Conference_on_Measuring_Behavior_and_the_6th_Seminar_on_Behavioral_Methods_to_be_held_in_Krakow_Poland_October_15-18_2021/links/5f802f22458515b7cf71fa14/Volume-1-of-the-Proceedings-of-the-joint-meeting-of-the-12th-International-Conference-on-Measuring-Behavior-and-the-6th-Seminar-on-Behavioral-Methods-to-be-held-in-Krakow-Poland-October-15-18-2021.pdf'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            Read Paper &nbsp;
                            <i className='icon-arrow-forward' />
                          </a>
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-3'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          CSE Tutor at UC San Diego CSE Department{' '}
                          <span>September 2020-March 2022</span>
                        </h2>
                        <p>
                          I was a tutor for CSE 15L: Software Tools and
                          Techniques (vim, linux, bash and shell scripting, unit
                          testing, and version control) for 700+ students. I was
                          also a tutor for CSE 100: Advanced Data Structures
                          (C++). Some of my responsibilities included holding
                          tutoring hours for one-on-one help, moderating labs in
                          course subjects, grading assignments, and developing a
                          bash script that is assigned to all students as a
                          project.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-4'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Tools Developer at UC San Diego CSE
                          Department <span>August 2020-June 2021</span>
                        </h2>
                        <p>
                          I helped develop and moderate the official website and
                          required tools for CSE 110: Software Engineering at UC
                          San Diego. Tools on the website include forming teams
                          based on student schedules, project submissions, team
                          feedback and discussion, managing and moderating
                          project schedules, and other needs in the UCSD
                          software engineering course. Technologies used include
                          React, Node, Express, sqlize, and GraphQL.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-5'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Project Advisor and Mentor at UC San Diego CSE
                          Department <span>July 2020-September 2020</span>
                        </h2>
                        <p>
                          I was a mentor and project advisor in UCSD Summer
                          Program for Incoming Students (in Computing). I helped
                          teach Python and algorithmic problem solving to over
                          50 UCSD computing students, hosted lab hours for
                          one-on-one help, and graded homework and labs. I also
                          hosted networking events and panels that greatly
                          benefitted the students. In addition, I advised
                          multiple project groups in developing WebApp and
                          machine learning projects and directly mentored four
                          students in the many aspects of this five-week
                          program.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry animate-box'
                    data-animate-effect='fadeInLeft'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-6'>
                        <i className='icon-pen2' />
                      </div>
                      <div className='experiences-label'>
                        <h2>
                          Software Engineer at Fairygon{' '}
                          <span>February 2020-September 2020</span>
                        </h2>
                        <p>
                          I worked on a variety of software tasks involving the
                          front end, back end, and software for the Fairygon
                          website, app, and product. An example of one of my
                          tasks was to help implement RESTful API for Siri
                          Shortcuts to make requests to the server for the
                          product to accomplish. This job also provided me with
                          experience in the tech startup industry.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className='experiences-entry begin animate-box'
                    data-animate-effect='fadeInBottom'
                  >
                    <div className='experiences-entry-inner'>
                      <div className='experiences-icon color-none'></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
