import React, { Component } from 'react';

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            href='#navbar'
            className='js-colorlib-nav-toggle colorlib-nav-toggle'
            data-toggle='collapse'
            data-target='#navbar'
            aria-expanded='false'
            aria-controls='navbar'
          >
            <i />
          </nav>
          <aside id='colorlib-aside' className='border js-fullheight'>
            <div className='text-center'>
              <div
                className='author-img'
                style={{ backgroundImage: 'url(images/about.jpg)' }}
              />
              <h1 id='colorlib-logo'>
                <a href='index.html'>Alvin Wang</a>
              </h1>
              <span className='email'>
                <i className='icon-mail'></i>{' '}
                <a
                  href='mailto:alvinwang922@gmail.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  alvinwang922@gmail.com
                </a>
              </span>
              <span className='email'>
                <i className='icon-phone'></i>{' '}
                <a href='tel:5109848499'>(510) 984-8499</a>
              </span>
              <br />
            </div>
            <nav id='colorlib-main-menu' role='navigation' className='navbar'>
              <div id='navbar' className='collapse'>
                <ul>
                  <li className='active'>
                    <a href='#home' data-nav-section='home'>
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href='#about' data-nav-section='about'>
                      About
                    </a>
                  </li>
                  <li>
                    <a href='#experiences' data-nav-section='experiences'>
                      Experiences
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <nav id='colorlib-main-menu'>
              <ul>
                <li>
                  <a
                    href='https://www.facebook.com/alvinwang922'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-facebook2' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/alvinwang922/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-instagram' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.linkedin.com/in/alvinwang922/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-linkedin2' />
                  </a>
                </li>
                <li>
                  <a
                    href='https://github.com/alvinwang922'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <i className='icon-github'></i>
                  </a>
                </li>
              </ul>
            </nav>
            <div className='colorlib-footer'>
              <p>
                <small>
                  I love <strike>boba</strike> coding!{' '}
                  <i className='icon-heart' aria-hidden='true' />
                </small>
              </p>
              <p>
                <small>
                  Inspired by{' '}
                  <a
                    href='https://colorlib.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Colorlib
                  </a>{' '}
                </small>
              </p>
            </div>
          </aside>
        </div>
      </div>
    );
  }
}
