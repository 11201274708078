import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/sidebar';
import Introduction from './components/introduction';
import About from './components/about';
import Experiences from './components/experiences';
import Projects from './components/projects';

class App extends Component {
  render() {
    return (
      <div id='colorlib-page'>
        <div id='container-wrap'>
          <Sidebar></Sidebar>
          <div id='colorlib-main'>
            <Introduction></Introduction>
            <About></About>
            <Experiences></Experiences>
            <Projects></Projects>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
