import React, { Component } from 'react';

export default class Projects extends Component {
  render() {
    return (
      <div>
        <section className='colorlib-work' data-section='projects'>
          <div className='colorlib-narrow-content'>
            <div className='row'>
              <div
                className='col-md-6 col-md-offset-3 col-md-pull-3 animate-box'
                data-animate-effect='fadeInLeft'
              >
                <span className='heading-meta'>My Work</span>
                <h2 className='colorlib-heading animate-box'>Projects</h2>
              </div>
            </div>
            <div className='row'>
              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInLeft'
              >
                <div
                  className='project'
                  style={{ backgroundImage: 'url(images/connect.png)' }}
                >
                  <div className='desc'>
                    <div className='con'>
                      <h2>
                        DevConnector <span>April 2020-June 2020</span>
                      </h2>
                      <p>
                        I created a MERN Stack application that acts as a social
                        networking platform for developers. Upon logging in,
                        users can view the profiles of other developers, create
                        posts that appear in the feeds of other developers, and
                        like or comment on the posts that appear in the user’s
                        feed. Some of the technologies I used included MongoDB,
                        Express, React, Node, Heroku, Git, Postman, and Bcrypt.
                      </p>
                      <p>
                        <a
                          className='btn btn-primary btn-learn'
                          href='https://github.com/alvinwang922/DevConnector'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View Project&nbsp;
                          <i className='icon-arrow-forward' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInLeft'
              >
                <div
                  className='project'
                  style={{ backgroundImage: 'url(images/groceries.jpeg)' }}
                >
                  <div className='desc'>
                    <div className='con'>
                      <h2>
                        Grosseries <span>February 2021</span>
                      </h2>
                      <p>
                        I was part of a team of four that developed Grosseries,
                        which is an immersive VR experience in which users can
                        immediately see how their grocery decisions impact the
                        world. Grosseries' mission is to educate everyday
                        shoppers about how their purchase choices can impact the
                        environment around them. We aim to improve outcomes in
                        sustainability awareness in a public desensitized from
                        statistics, news, and even infographics. In the VR
                        world, users are put in an outdoor grocery store on an
                        island and adding grocery items to their shopping cart
                        could potentially affect the beautiful nature around
                        them. For example, purchasing a plastic water bottle
                        causes plastic bottles and trash to wash up on the beach
                        and the sky to turn orange due to the carbon emissions.
                        This project was submitted to SDHacks and won Best
                        Sustainability Hack, Best Hardware Hack, the echoAR
                        Challenge, and the Wolfram Alpha Prize.
                      </p>
                      <p>
                        <a
                          className='btn btn-primary btn-learn'
                          href='https://github.com/alvinwang922/grosseries'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View Project&nbsp;
                          <i className='icon-arrow-forward' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInRight'
              >
                <div
                  className='project'
                  style={{ backgroundImage: 'url(images/disaster.jpg)' }}
                >
                  <div className='desc'>
                    <div className='con'>
                      <h2>
                        CaliAlert <span>February 2020</span>
                      </h2>
                      <p>
                        I was part of a team of three that created CaliAlert,
                        which is a website created with HTML, CSS, and
                        JavaScript where users input their phone number and zip
                        code which we stored in Google’s Firebase. When the USGS
                        Earthquake Catalog API sends a warning about a predicted
                        or current natural disaster, we used the Twilio API to
                        send a text email with safety tips and information about
                        the natural disaster to people living in the zip code
                        areas that are or could be affected by that disaster.
                      </p>
                      <p>
                        <a
                          className='btn btn-primary btn-learn'
                          href='https://github.com/alvinwang922/CaliAlert'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View Project&nbsp;
                          <i className='icon-arrow-forward' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInTop'
              >
                <div
                  className='project'
                  style={{ backgroundImage: 'url(images/map.jpg)' }}
                >
                  <div className='desc'>
                    <div className='con'>
                      <h2>
                        EventNow <span>October 2020-Present</span>
                      </h2>
                      <p>
                        I am part of a team of four that works on EventNow,
                        which is a website linked to an app in which
                        organizations can input events that are then displayed
                        on a map. Users can then look for events that they are
                        interested in through keyword searches or simply
                        clicking on nearby icons on the map to view the details
                        of the event. We use HTML and CSS for the front end of
                        the website, JavaScript (node), JSON files, and Google
                        Maps API for the back end of the website, and Dart to
                        develop the app.
                      </p>
                      <p>
                        <a
                          className='btn btn-primary btn-learn'
                          href='https://github.com/aazurill/EventNow'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View Project&nbsp;
                          <i className='icon-arrow-forward' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInBottom'
              >
                <div
                  className='project'
                  style={{ backgroundImage: 'url(images/water.jpg)' }}
                >
                  <div className='desc'>
                    <div className='con'>
                      <h2>
                        Staydrated <span>December 2019-January 2020</span>
                      </h2>
                      <p>
                        I created an iOS and Android app using the programming
                        language Dart and the UI framework Flutter that helps
                        users track and analyze their daily water intake based
                        on the user’s age and weight. This includes many sources
                        of water intake other than water itself such as fruits
                        and vegetables.
                      </p>
                      <p>
                        <a
                          className='btn btn-primary btn-learn'
                          href='https://github.com/alvinwang922/Staydrated'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View Project&nbsp;
                          <i className='icon-arrow-forward' />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='col-md-4 animate-box'
                data-animate-effect='fadeInLeft'
              ></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
