import React, { Component } from 'react';

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section
          id='colorlib-hero'
          className='js-fullheight'
          data-section='home'
        >
          <div className='flexslider js-fullheight'>
            <ul className='slides'>
              <li style={{ backgroundImage: 'url(images/bg1.jpg)' }}>
                <div className='overlay' />
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='text-center col-lg-12 js-fullheight slider-text'>
                      <div className='slider-text-inner js-fullheight'>
                        <div className='desc'>
                          <h1>Welcome to my website!</h1>
                          <p>
                            <a
                              className='btn btn-primary btn-learn'
                              href='https://docs.google.com/document/d/1WldI1qA28TWGzSDPyKJ3kTWO9EHkgemoC05Fvii3IOU/edit?usp=sharing'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              View Resume&nbsp;
                              <i className='icon-download4' />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li style={{ backgroundImage: 'url(images/bg2.jpg)' }}>
                <div className='overlay' />
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='text-center col-lg-12 js-fullheight slider-text'>
                      <div className='slider-text-inner'>
                        <div className='desc'>
                          <h1>Check out my projects!</h1>
                          <p>
                            <a
                              className='btn btn-primary btn-learn'
                              href='https://github.com/alvinwang922'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              View Projects <i className='icon-lightbulb' />
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
