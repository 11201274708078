import React, { Component } from 'react';

export default class About extends Component {
  render() {
    return (
      <div>
        <section className='colorlib-about' data-section='about'>
          <div className='colorlib-narrow-content'>
            <div className='row'>
              <div className='col-md-12'>
                <div
                  className='row row-bottom-padded-sm animate-box'
                  data-animate-effect='fadeInLeft'
                >
                  <div className='col-md-12'>
                    <div className='about-desc'>
                      <span className='heading-meta'>About Me</span>
                      <h2 className='colorlib-heading'>Who Am I?</h2>
                      <p>
                        I am an undergraduate student pursuing a Bachelor of
                        Science degree in Computer Science with a Mathematics
                        minor at the University of California, San Diego.
                      </p>
                      <p>
                        I love learning new things and efficiently solving
                        algorithmic problems. Throughout my past experiences,
                        I've been able to explore many different fields within
                        computer science, such as infrastructure software
                        engineering, machine learning, and full-stack
                        development. My favorite thing about this amazing field
                        is the endless ways to think, grow, and make an impact.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='colorlib-about'>
          <div className='colorlib-narrow-content'>
            <div className='row'>
              <div
                className='col-md-6 col-md-offset-3 col-md-pull-3 animate-box'
                data-animate-effect='fadeInLeft'
              >
                <span className='heading-meta'>What do I do?</span>
                <h2 className='colorlib-heading'>Interests</h2>
              </div>
            </div>
            <div className='row row-pt-md'>
              <div className='col-md-4 text-center animate-box'>
                <div className='services color-1'>
                  <span className='icon'>
                    <i className='icon-bulb' />
                  </span>
                  <div className='desc'>
                    <h3>Machine Learning</h3>
                    <p>
                      One of my current endeavors is doing research in the field
                      of supervised machine learning and computer vision, where
                      I work with technologies such as OpenCV and PyTorch.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 text-center animate-box'>
                <div className='services color-5'>
                  <span className='icon'>
                    <i className='icon-layers2' />
                  </span>
                  <div className='desc'>
                    <h3>Full-Stack Development</h3>
                    <p>
                      I have experience developing full-stack web apps using
                      technologies and languages such as React, HTML, CSS, Node,
                      MongoDB, Express, Firebase, Postman, and Jest.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 text-center animate-box'>
                <div className='services color-3'>
                  <span className='icon'>
                    <i className='icon-repeat' />
                  </span>
                  <div className='desc'>
                    <h3>DevOps</h3>
                    <p>
                      I enjoy writing scripts and producing tools that help
                      shorten software development life cycles. My experiences
                      in this field include scripting in Python and Bash.
                    </p>
                  </div>
                </div>
              </div>
              {/*
              <div className='col-md-4 text-center animate-box'>
                <div className='services color-3'>
                  <span className='icon'>
                    <i className='icon-phone3' />
                  </span>
                  <div className='desc'>
                    <h3>Software as a Service</h3>
                    <p>
                      As someone who loves helping out others, I enjoy using my
                      skills in computer science to develop software that
                      promotes safety or makes people's lives more convenient.
                    </p>
                  </div>
                </div>
              </div>
              
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>Jenkins , Kubernetes , Docker </p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
